import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum RightPanelKeys {
    Search = 'search',
    Spreadsheet = 'spreadsheet',
    HighlightSet = 'highlight_set',
    Image = 'image',
    Website = 'website',
    MapIt = 'map_it',
    Submarkets = 'submarkets',
    SubmarketStatistics = 'submarket_statistics',
    MarketViews = 'market_views',
    QuickLayer = 'quick_layer',
    QuickLayerFromLibrary = 'quick_layer_from_library',
    SitePlans = 'site_plans',
    Assistant = 'assistant',
    WeatherWidget = 'weather_widget',
    LineOfSightWidget = 'line_of_sight_widget',
    ViewshedWidget = 'viewshed_widget',
    DaylightWidget = 'daylight_widget',
    MarketSurvey = 'market_survey',
    TableToHighlightMatch = 'table_to_highlight_match',
    CsvStyle = 'csv_style',
    Amenities = 'amenities',
}

export const RightPanelLabels = {
    [RightPanelKeys.Search]: 'Search',
    [RightPanelKeys.Spreadsheet]: 'Spreadsheet',
    [RightPanelKeys.HighlightSet]: 'Highlight Set',
    [RightPanelKeys.Image]: 'Image',
    [RightPanelKeys.Website]: 'Website',
    [RightPanelKeys.MapIt]: 'MapIt',
    [RightPanelKeys.Submarkets]: 'Submarkets',
    [RightPanelKeys.MarketViews]: 'Market Views',
    [RightPanelKeys.SubmarketStatistics]: 'Submarket Statistics',
    [RightPanelKeys.QuickLayer]: 'Quick Layer',
    [RightPanelKeys.QuickLayerFromLibrary]: 'Quick Layer from Library',
    [RightPanelKeys.SitePlans]: 'Site Plans',
    [RightPanelKeys.Assistant]: 'Assistant',
    [RightPanelKeys.WeatherWidget]: 'Weather Widget',
    [RightPanelKeys.LineOfSightWidget]: 'Line of Sight Widget',
    [RightPanelKeys.ViewshedWidget]: 'Viewshed Widget',
    [RightPanelKeys.DaylightWidget]: 'Daylight Widget',
    [RightPanelKeys.MarketSurvey]: 'Market Survey',
    [RightPanelKeys.TableToHighlightMatch]: 'Table to Highlight Match',
    [RightPanelKeys.CsvStyle]: 'CSV Style',
    [RightPanelKeys.Amenities]: 'Amenity',
};

export interface RightPanelItem {
    name: RightPanelKeys;
    visibleInPresentationMode?: boolean;
    hiddenInClientShare?: boolean;
    isHalfHeight?: boolean;
}

const rightPanels: {
    [key in RightPanelKeys]: RightPanelItem;
} = {
    [RightPanelKeys.Search]: {
        name: RightPanelKeys.Search,
    },
    [RightPanelKeys.Spreadsheet]: {
        name: RightPanelKeys.Spreadsheet,
    },
    [RightPanelKeys.HighlightSet]: {
        name: RightPanelKeys.HighlightSet,
    },
    [RightPanelKeys.Image]: {
        name: RightPanelKeys.Image,
    },
    [RightPanelKeys.Website]: {
        name: RightPanelKeys.Website,
    },
    [RightPanelKeys.MapIt]: {
        name: RightPanelKeys.MapIt,
    },
    [RightPanelKeys.Submarkets]: {
        name: RightPanelKeys.Submarkets,
    },
    [RightPanelKeys.SubmarketStatistics]: {
        name: RightPanelKeys.SubmarketStatistics,
        visibleInPresentationMode: true,
    },
    [RightPanelKeys.MarketViews]: {
        name: RightPanelKeys.MarketViews,
        isHalfHeight: true,
    },
    [RightPanelKeys.QuickLayer]: {
        name: RightPanelKeys.QuickLayer,
        visibleInPresentationMode: true,
    },
    [RightPanelKeys.QuickLayerFromLibrary]: {
        name: RightPanelKeys.QuickLayerFromLibrary,
    },
    [RightPanelKeys.SitePlans]: {
        name: RightPanelKeys.SitePlans,
    },
    [RightPanelKeys.Assistant]: {
        name: RightPanelKeys.Assistant,
    },
    [RightPanelKeys.MarketSurvey]: {
        name: RightPanelKeys.MarketSurvey,
    },
    [RightPanelKeys.WeatherWidget]: {
        name: RightPanelKeys.WeatherWidget,
        isHalfHeight: true,
    },
    [RightPanelKeys.LineOfSightWidget]: {
        name: RightPanelKeys.LineOfSightWidget,
        isHalfHeight: true,
    },
    [RightPanelKeys.ViewshedWidget]: {
        name: RightPanelKeys.ViewshedWidget,
        isHalfHeight: true,
    },
    [RightPanelKeys.DaylightWidget]: {
        name: RightPanelKeys.DaylightWidget,
        isHalfHeight: true,
    },
    [RightPanelKeys.TableToHighlightMatch]: {
        name: RightPanelKeys.TableToHighlightMatch,
    },
    [RightPanelKeys.CsvStyle]: {
        name: RightPanelKeys.CsvStyle,
    },
    [RightPanelKeys.Amenities]: {
        name: RightPanelKeys.Amenities,
    },
};

interface RightPanelState {
    panelStack: RightPanelItem[];
}

const initialState: RightPanelState = {
    panelStack: [],
};

export const rightPanelSlice = createSlice({
    name: 'rightPanel',
    initialState,
    reducers: {
        setActiveRightPanel: (state, action: PayloadAction<RightPanelKeys>) => {
            const panel = rightPanels[action.payload];
            state.panelStack = [panel];
        },

        toggleActiveRightPanel: (state, action: PayloadAction<RightPanelKeys>) => {
            const panel = rightPanels[action.payload];
            state.panelStack =
                state.panelStack.length === 1 && state.panelStack[0].name === panel.name
                    ? []
                    : [panel];
        },

        clearRightPanel: (state) => {
            state.panelStack = [];
        },

        pushRightPanel: (state, action: PayloadAction<RightPanelKeys>) => {
            state.panelStack.push(rightPanels[action.payload]);
        },

        popRightPanel: (state) => {
            state.panelStack.pop();
        },
    },
});

export const {
    setActiveRightPanel,
    clearRightPanel,
    toggleActiveRightPanel,
    pushRightPanel,
    popRightPanel,
} = rightPanelSlice.actions;

export default rightPanelSlice.reducer;
